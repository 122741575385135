     �  PFFTM�F��  ڼ   OS/2O�^B  X   `cmap��  �  Jcvt  D     gasp��   ڴ   glyfȖ@  \  �head�u   �   6hhea�      $hmtx��  �  loca�@�:    Fmaxpt   8    name�˲U  �h  �post'6�	  �  �      �@2_<�      �G�{    �G�{�����             ��� . ��                  d   " �             @ .     ��   Lf   GLf   �  �                       PfEd �  ���� .� E                 �      �   �              *  *  *  *         U    �  �  U    U    U    �  �    U  j  j        *     u� `  @    U  *    *          @  �    j  �  �  j  �  j  j  �      @            ��            *    *  *      @  @  @  @  @  @  @  @             *       U    *  j  *     � @    ( *���� �   U @ @ @ @   @    U   *  * * ��  *  @ *   * * *    U   *      *  * * *    * * *  * ' * U   * * U  *  + @ � &  * * Q  *  j   @          U  U  *  *       *    * @ * * *     @ * @   *   @ * U U     U  @ *         @   @        * * * *         *   @   @ * *   @                     U     j   *   '   * *               D       (        ���    � ����                                                                                                                                                                                                                                                                        D   * * * * \ � �D��6��>� X���6���0|��6���<�	
	�	�

Z
�
�H�n�f��.Nn��� X��r����^�h�$�H��.��J�� D�d��N�&Z��X���N��<� , ` �!F!�!�"N"�#|#�$$\$�%%T%�&T&�&�'@'�(J(�(�)T)�**�*�+"+\+�,,f,�,�-:-�-�.,.�/ /|/�0f0�1F1�1�242�2�3V3�44:4�4�5&5�6H6�6�6�7$7z7�7�88L8�9J9r9�9�:@:�:�;;�;�<:<�=v>>�?8?�@@6@j@�@�A2A�A�B2B�C$C�DDJD�D�E*E�E�F0FjF�G�HTH�IInI�JJ~J�J�K K�L L�M MjM�M�NFN~O*O�PrP�P�Q8QjQ�Q�RHR�R�S$S�TTzT�T�U0U�U�VFV�WWFW�W�X$X�X�YRY�ZZ�[[R[�\\>\�\�]B]�^.^�_8_f_�_�`N`�`�apa�b       �U   .� /<� �2��<� �2 � /<� �2��<� �233'3#�wffU��3    ����   "#";2?2?3264&+"�;F	
U,l;F		U,l��	
�����D    ���� , / ;  7264&+"&=4>3!2+";26=4&#!"37;26/&"k
V&&��&&n==�	�	k@	�
	�	&�%%�&+JJ�     ����    (   "&4624&"24.#"2652654.#"�q�qq�����L
	*
	�qq�q���@
	U		L	
     ����   - 8  73#/;2?6=4/&+"4.#"2652654.#"@q�qq�q+}	�	}}	�	} 
	*
	qq�qq��	}}	�	}}
	U		L	
     ����   * 2  2#!"&73!2654'&"%4&"32>5264&"������%j%	�H�		*m
��
.��%%.���		U	M    * *�V   ) 5  %2>54&#!"3%264&#!"32654.#!"3264&#!"3�
	� 		@		��		�	
��
	@		� 		�
V�

U   * *�V   * 6  %2>54&#!"3%264&#!"32654.#!"3264&#!"3�	��		�		��		�	
��
	�		��		�V�

U     * *�V   ' 3  %264&#!"3%264&#!"32654.#!"3264&#!"3k��		�		��		�	
��
	+��		�V�

U    * *�V   , 8  %2>54&#!"3%264&#!"32654.#!"3264&#!"3�	��
+		��		�	
��
	�		���
V�

U    ����     H   "&4624.#"262654.#"2>54.+"2654&+";#"&'U2F22F$@
	�
@	�	@
*5T1JmyG22G2V#��		*
	j
	a��a	
0N-bI   ����    ) 5 A O [   "&4624&"2"326/&#";2654.#32?6&#"26/&#"3264&+"3%6&#"327�q�qq�����0zzo		�
��z6zzo		�
9z�qq�q���V��
`
�
����
	`
�
    �� �   , =  4.#"!4&"3!2655!%3!26=4.#!"";2>54&#�
	��	�	�V��+�
�*�
V
	�  		��		+@@Ukk
�
		   U �k  $  4.#"32>564&"'&"2?
		�����U
	��	�����  ����     .   "&4624&"2"2?64&"'74.#"265�q�qq����®UUFF[
	�qq�q��� UUFFF
	�		  � @�@     4&"2?264&+54&"3����"�����    � @�@    "264/4&"#";265������@���     U �k  &  %264&#!"32>54/7654.#"�		��		���
	������
��  ����   # 1   "&4624&"24.#"264/76264&+"3�q�qq�����L
	UUFF@		�	
�qq�q���@
UUFFb	
  U �k    7"3!264&#&"2?64/k		*		�����������  ����     .   "&4624&"2&2?64/&"'";264&#�q�qq�����vUUFFF	
�		�qq�q����UUFF[	
   U �k  $  732>54.#"2?264/&"�	
	�����+	*
	�����    ����   # 1   "&4624&"2&264/&"32?26=4.#"�q�qq�����UU
FF[
	�qq�q����UU	
FFF		�
	    � @�@    $264/&"'26=32>54&+"b����
�@���
    � @�@    62?64&"7";26=4&#���
�^���
��     ����   @  $"&4624&"274&"326=4&#"327654&#"&4632"&5k?X??X&4&&4Q'$2�a@l??l@P?
4AOqqO4Y3#�X??X?�4&&4&�		j'2$a�?l�l?1(q�q3Y4   U����   3  ".54624&"326/.#"'&76.#"?�";F;"KjK+d�d.O.G
EE`";##;"5KK{�ddG.O-��

�**��9     j  ��   %  726=4&"2654&"26=4&#"��� 	
��V��V
     j  ��   %  %26=4&"2654&"26=4&#"k��	
��V���	
       * V    ,  !2#!"&=467"3!26=4&#26=4&"@+		��		%%+&&j+
�		�	*%�%%�%�		*		    * V  : F \  ";2+";26=4&#2654.+"&=4>;264&+"3%26=4&"'6&#";32?6&+@		+D		D&&� 
+	
D		D&&��UX?UXU	�	%�%��	
	�
%�%�		*		_	�_	�    *���� 	 & 4  2!6544&"3!2>54'&'&'&6&#""&#"2�X?'��' W|W			�
		^J�?,iAAi,,>XX>P4
4�      �� �   : P \  6&#""&#"2726'&54.#"'6&#"3!264&+6542654&#"7632$"264'7Jh
	�
			+�'�	W>-&	 ,?
����* �
/6
98�P4Aj%	=X>,
��*�   u���� ! $ '  4625462"&="&4?'77'uQuffuQff�BB-Q�vffv�QffQ�B�B�  `  ��    .  7532#';264&+"532#';2654.+"��&&��,??,�
+�%%��,?1�Հ%5&��	>X?
���&5%��?,1     @����   0  73265463!264&#!"!"&5463!%"3!2654&#@	 ��"U��
��",  	#4@+#��,	�	  ����  # , A  732&#;22654&+"46;#"#"26546;2654&@k&��>,�
 &k���,>�	U&�+��		+,>
[&��K>,��			@	   U����    4>;2'&4&+"?6�
�tt+&�&��U
	��S		S)U%%��aa  *����  # 3 ?  %/&=4?6&?6=4'&?654&26=4&"����  �  �  �  ��
��
�U�UU�JU%�%UU%�%llf�		�		   ����  # D  !2#!"&=467"3!26=4&#32654&+"32654>;2UV	��		&&V&&k
	&V&

V	�	�	+%�%%�%��
	U%%��
	U
	   *����    . ? N  !2#!"&54>7"3!2654&#'4&"32>5'4.#"3265"3!2>54&#k*		��	
%%*%%*
�
	�		�	U	��		+
+%��&&+%		U	
U
	U	@    �� �  0 8 @  %#!"&=4>;2?3;254&+'&+"#"3!26&"&4624&"2�	��	
U$j$U	+&J$�$J&&�&�?X??X&4&&4+		�
	77		��%7		7%�%%�X??X>�5&&5%   �� �  # ? \  "264'	6&#"#"&547654&32";;226=4&+'&#264&#!"&=4>;264&+"3��*4!%
.>-8x		u$U	&J$�		��	
@		@&&��*���	&" 8,?V7		�		�%7	�V	�
%�%       �� % 8 H S  26=463!2+";2654&#!"&326'.&326'.2654.#"V�	
�&&��&
'B*
jH
%
6@		+		� 		
& &&l	)B'
JjO	%%7X
  @ @�V   4.#"'&#"2?6�
	�\	
k�@
�\
	k�     ����  3  $""&4632654'&#"32>=454.#"'&"2?6�q�qqO)%.2a��a@l?
	�1@��Oqq�p�?l@�
�1@�   ����  <  7"2?654.#"'&4&"#!"&546;2>54&+"3!265�
@�
	�1��		�
�&&+&�
@�	
�1+		�		*	
	%��%%    j j�   "2?64&"'���qq��qq     � *VV   $264/764&"7qq��+qq��     � *VV   62?64/&"���qqI��qq  j j�   $264/&"2?w��qqk��qq     � *�V  $  6"2?64&"'&"2?654.#"'�kk\\kk
	\\�kk\\�kk	
\\   j @�@  $  6264/764&"2>54/764&"�\\kk�
\\kk@\\kk
	\\kk   j @�@  !  $2?64/&"2?64/&" kk\\�kk\\^kk\\kk\\     � *�V  !  $264/&"2?264/&"2?bkk\\kk\\�kk\\�kk\\     ����     - ; G   "&4624&"2"&4624&"272654.+"3'"326/&32?6&#"�q�qq�����
?X??X&4&&4�
�		�
bbua�qq�q���X??X?�4&&4&�
T	����
�   ����     "&4624&"2�q�qq������qq�q���   @���� + / @  ";2#!"&546;264&+"3!2654&+3#5";26=4&#U+		� 		++&& &&�����	��+	&��&&+&+V++    ����      "&4624&"24&"654/�q�qq�����LVJ�qq�q���k		�+%  ���� �  %  %2+".5463234&+.#";2�#22#�7'W>"<*�K5
$.:PppP�5�2F2(7>W3 �jK/"q�p    ���� �   - < H V �  74&"326=4&#"32654.#"326=4.#"2654&"26=4&"32>577>54&+.#"654'&54632;2�


�

	V	
�"K55J)Pp&"
8W>"<*#2++
	�
+
	U
+
	�+		�		+�		+
	
#.5K%:!qO-N
-H>W3 2#    ����� , B  %7>54&+.#"654'.54>32;2'6&#";32?6&+�,:K5c>Pp6-$)(D)3Q#2&YVX?VXW		F.5K9GqO5ZE*(E(?12#/a

�_
�
   �����  > J  4&3:327654&+&54676327654&'#.'";2 "264'�-6qO � �?Y*#O
J6Y:
/I#2�I��*UZ5Pp
X?)F�5J5F
	>.2#Q�*� ���� �    , [  %4.#"3265'4&#"326574&"26577>54&+.#"654'&54632;2k
	�

U�"K55J)Pp&"
8W>"<*#2��
	�
�
	�		��
#.5K%:!qO-N
-H>W3 2#    ���� � - 8 @ H S [ c  %7>54&+.#"654'&54>32;2%3264&"264&"6264&"2654.#"6264&"264&"�#*K55J)Pp&"
8(D)"<*#2� 
	I
	II@'5K%:!qO-N	-H(E(3 2#*

Va	
tb    *�V  $  $2?64/&"'4.#"264/76@��qq�
	��qqI��qq�
��qq     ����   % 5 E S  7/?6=4/&26=4.#"%4&'&?6?654/&4.#"265@����+
��

��
�
	 ��	��	�*��	��	 
	 �}}~}}���������		�
	����		�����		�
	�		    *����  # 3 C S c o  %/&=4?6&?6=4'%&?654&26=4/&%4&26=76%&?654&26=4&"����  �  �  �  ��
``
Uu
`
VJ`
V
�{
��
�U�UU�JU%�%UU%�%,882��		o72D7o		b2�llf�		�		    �� �  % 6 D R ^  ";2+";264&#+"&=';26=4.#!"74&#"26574&"326574&"265�		%

,??,*&�&+?,�,>
��	�	
V
	U+&

?X?+�&&��,>>,�
�
@		@@
	@@		   *���� 	   )  #"&5463!2654&#!"3"&5732#�		*%%��%%�	�		k��	*	��%*%%��%		*V	��	   	 *����    # , 0 9 B K  #"&462"&46;2"&52+546"32643#=4&"3#"2657264&#�+$$�+$$+NF2U#2� VV2F22#UU#22F2V2F22#$�+$$+$+C2#U2FxVVU#22F2V2F22#UU#22F2   ����    "   "&4624&"27?6?6&�q�qq�����}UU6�
.�
�qq�q���U.
�.
  ����  ! M  732+".=467";26=4&#2654.+".=46;232>=4&+"3��		�
	%%�&&���
	
%�&&�
�		�	*%�&&�%�	�

&&�%    @  ��  )  74.#"264/7674&"#!"3!265�
	kk\\�%� 
 ,?�
kk\\��&	
>,  @  ��  &   "2?64/4&"3!2654.#!"&5I\\kk�?, 
� % \\kkq�,>	
&     @  ��  '  $4&"'&"2?264&+"3265463@\\kkq�1
	&w\\kk�1� 
 %   @  ��  *  $264/&"32?264&+"&54.#"3"kk
	\\��&	>,�kk	
\\�% � ,?    @  ��  )  6"2?654.#"/";232>54&#�kk
	\\��&	
>,�kk	
\\�%� 
 ,?   @  ��  $  2?264/&"";2654&"#�\\kkq�,>&	\\kk�?, � %   @  ��  .  6264/7654.#"326=4.#!"3!2�\\	kk�
1� 
 �\\	kkq
�1	
   @  ��  &  $2?64/&"26=463!2654.#!"+kk\\�% 
� ,?�kk\\��&	
>,      �� �   # 3 C S a q � � � �  !2#!"&5467"3!2654&#53';26=4&+"74.#"26574&#"32654&"326574.#"32>5";2654.#";264&#%";264&#";2>54&#� 		� 		%% %%�V�	�		�	*
�	

	�
	�
		
V
	@
@	@�*@		@@
	U	� 		 	+%� %% %�VVk�		�	�
@		@
@
	��		@
@
	@
@
		
j	jj
	      �   +  !2#!"&5467"3!2654&#"3!264&#@�		��		%%�%%�U�U	� 		 	+%� %% %�    �� �  -  "3!2654.#!"&5543%22654&#"�%@
��	�	?	%���	��%	
	?v		��@%     ����    ) 7 C   "&4624&"27264&+"3!264&+"3726=4.#"26=4&"�q�qq�����t		U		� 		U	
�
	�qq�q����	
�		U
	�V		U		     *����   9 Z   "'&4762"3276767&'4&""'&/4&"276%4&"27654.#""'&/�%<�<%%<�<[�66UK5J5�5%<�<%��%<�<%
	5�5s;"";##
�						""�		��##*
	��		       � 
  ( 4  2#!'7%!"3!2654&4&"2?&"264/�		����
��
&&E�����U	� 	��+��& &���$��  ����       "&4624&"2"&4624&"2�q�qq�����2F22F$$�qq�q���F22F2g$$  U  ��    # +   "&4624&"2"3!264&#"&4624&"2@&4&&4�		*		U&4&&4Z4&&4&IV{4&&4&I    ����     7";264&#6"&4624&"2�	
�		kq�qq������	
:�qq�q���     *����   -  !2#!"&5467"3!2654&#";264&#k*		��		%%*%%�	
�		k	��		*	*%��%%*%�	
    j����  1  4&#"265264&+";2+";264&+"&463	
V�(88(j  �		�(88(j  �
�*k9O8 ,9O8 ,   *����  , 8  %4&"#!"&=4&"3!265$"2?64&"'2654&"�	��	%*%��kk\\G�		U		U		U%%�kk\\z		 		  �����   N  6"2?64&"'74&"26577>54.#*#.#"32654'&546323:3�UUFF[� 'c>Pp0
	&X>3Q#1%	kUUFF\		�		>7 ' 9HqOI7	*9=X?12#,  ����   S   "&4624&"27654'&#"&'67654&#"&'&#""3673276767326'&'632�q�qq�����j
74H2	
-@5
2Iz
�Q\MEO
.�qq�q����
;	46J	
E0!n
	c:]
Y<    @����    $"&4?32>54/&"�W|W+jj��qO'G48yy�|WW|,ii�q3G'P8yy   ���� % . >  264&+"3!26=4&#"#!"&5463$2??6?64&"��&&+&
	��L�-���U�'7�U&��&&�
�+	.�-����7'�  ����     27	?67654.#"� ��L��v %!��| ��L�  !%��    (����   &  %";2654.#27	?6764&" 		�	
J��-��V'8��+
B��-��
7'��   *���� % 6 B  %4&"+"&=46;2654.+";265";26=4&#2?64&"�	�		�
�%%�%U		k	� ���		�		
%�%%jk		�	���    ��  �   ' /  $"'&'676227676'&'&"$"&4624&"2L�E&&E�E&&�Q44Q�Q442F22F$$+A%//%AA%//%lM27

72MM27

72�F22F2g$$   �����  8 T `  %4&#"#"&547654&#"3276'7632327676'&'&4&"'&'67654&3676 "264'C				2#%�
LE&

4hx�
3?LE&%;	G*4Q]N>����*�			&#2�A%/
!'

72c��&A%/@.7O

72M/��*�  �����  J  7326=46;#";#"#54&+#"#";;26=32?6&+5326=4.�*	2#++	:/	*	*�@5K++V+
@@	
�	@#2+@	*	��	 K5+V�	�U+	U
      �o      %564/&7'564/&7����

Z����j�{����j�{���   ����   $ 0   2#574&";2?&4&"27264&+"3)X?����X|+�	�	�T��*		�		�>Y����{X,�	�	�a��*�   
 U����     . 9 D M Y a r  46;#"&;26=4.+"532#';264&+"462#".'3264&"46;"&26=4&+"&46;#"';26=4&+"�66J9'K	
K'�6`	K'99'K	* ,*-'99O8k6 ,J9O8	K'66J9'K		K$5,k]O8	�
�k��	8O9�  ,,9O88�,5]P88(K, k5'9	�	#    @����   2  %#!"&546;'#"3!26=4/&4&";2654.+�	� 		����&& &��
�V��&��&&�	�		�		
  @����   1 =  %#!"&546;'#"3!2654/&4&";264&+";264&#�	� 		�sj�&& &�
�k�		�		Vs�&��&& 	�		�
�    @����   1 = I  %#!"&546;'#"3!2654/&4&";264&+26=4&"";264&#�	� 		�sj�&& &�
�kU+		�		Vs�&��&& 	�		�
�		�		+     @����   1 B N _  %#!"&546;'#"3!2654/&4&";264&+2654.+"3264&+"372654.+"3�	� 		�sj�&& &�
�k	�
	�		�		*	*

Vs�&��&& 	�		�
�	
	U�	

    	 ����   . : H T ` l z  !2#!"&5467"3!2654&#4&"32654&"265%"3!264&#%";264&#";264&#3";264&#'";2654.#YN

��

((N((�	���	
�		�V		j		j		j		�		j		j		j	
�
��

N
+(��((N(		�V	�		�V		�	
k��	
     ���� 	   %'54/!7!"6=7654&*�N���V�U���~���ă+��	    @����  C O  %"'&#"567632327"'&#"3276763232>7654.#"26=4&"U19$%!19$%!19$"
	!19$"
	���	�	� 		� 
		 
�k		�		   ����  *  %#!".54>;;254&+'&+"3!26���
`$�+&�$k&&V&+	*
7		��%7	%��%%   ����  * :  %#!".54>;;254&+'&+"3!26%";2>4.#���
`$�+&�$k&&V&��

�

+	*
7		��%7	%��%%�



   ����  * : J  %#!".54>;;254&+'&+"3!26'4."32>5'";2>4.#���
`$�+&�$k&&V&�

	
U

�

+	*
7		��%7	%��%%�

�
V



  U����  $ (  7';264/326=4&#!"#"2657#53�Mw�	qb		��	qb
�M�wULLq	�	q
�
��k    ����   + : H   "&4624&"2'26'."327>2'3264&#"3264&#"�q�qq�����#$#
#&"�		
�		
�qq�q����

			�

    ����   / ; E R \ i  %4.#"!54&#"3!265%5!%3!26=4.#!"2654&"'"&4632'";26'&'&2+6763264&#"�
	��	
V����U
�	
�V	�KC,,`
 �CA`,,+ �
	��	
�		�@@Vk
	k
��		@#k,>,
5$#+#k,>,#$5
  *����    # + ;  4&"265$"&4624&"2"&4624&"2%4&"#"326�@2F22F$$�2F22F$$dG		X}�		� 		�F22F2g$$�F22F2g$$�		Gd}   U +    +  $"&4624&"2%";2654.#3";264&#k?X??X&4&&4��
	
�				�X??X?�4&&4&U
		
     *����     6  $"&4624&"2"&4624&"2264&#"&54&"26=�2F22F$$�2F22F$$�		GdZcF22F2g$$NF22F2g$$�dG		� 		�(-     *����     3 ?  $"&4624&"2"&4624&"27";226=4&#4&"265�2F22F$$�2F22F$$�@	%�cF22F2g$$NF22F2g$$@	�		�%U		� 		   ���� y  265<54'67654'6'&'&#"&&#"#"'&'&'&'&7265<547654&'&'&5476'&77676767M%>		FG0!
>$
,
2+	
!
EE		
	+1+	;*b0%+)
		)+%0a+
%		;K&			&K	T	    �����  (  732?'676'&'.#"#'.#"7��(/��/(U@
/�/@	��|�7��9�|a,�	��	�+�		  ����    % 3   "&4624&"2"3!264&#&'672767&'&"�q�qq�������	
�		�>>>NXXX�qq�q��� 	
�OeeOOeea��aa��    *����    + / @ D U  53';26=4.+"53';2>=4&+"53';26=4.+"53';26=4&+"Uk�	�	
�	k�	�	�
+k�	�	
�	�k�	�		�
 kk��		�
�kk��	�	
��kk��		�
�kk��		�	
    ��   2 = H  %264&#!"3732#!"&=763!26=4/.+"264&#"3264&"�		�V	
o�G��Gx&V&I�'IV	
U
�	
��{		{���&&��#�Z	

   @����   - ;  "3!2654.#"3!2654.#'6&#"32676&#"3267U
V
��
V
�
+
�
+
	
	
�	
	
�	��	�	��	     *����  $ 5 B S  726=46226=4&"32+"&5#;26=4&+"+"&=46;54.+";26+d�d}�}*+			*%&&@
�			+*
@&&%@		�GddG�		�X}}X@@		%%@&
j		@Uj
&@%%   ����  )   2.'&4622?4&"'&"27>7BO9"!9O�Qs((sQ(%��%k9O""O9~sQ((Qs(%��%    ����   2 :   "&4624&"2327>32767654.#"264&"�q�qq����³	$(,4�qq�q���2	#' �  *����  #  %/&=4?6&?6=4'����  �  �  �  U�UU�JU%�%UU%�%    *���� 
  3  %#!"&=7%3!26=4/&26=3326=4&+"�	��	����%*%���V
		�	ᄄ
�&&�
�

���		��
	�		     *����   ' / @  !2#!"&5467"3!2654&#"&4624&"2264/&"2?k*		��		%%*%%� ,,		�k��\k	��		*	*%��%%*%t,  ,:		�k��\      ��  - B  7#";;2?3264&+"#'&'32#!"&=763!26=4/.+"��	
t%V%t		�%>%�G��Gx&V&I�'I�	
7		7	77	��{		{���&&��#�     ����    #   "&4624&"2'26=4&"4264&"�q�qq�����v�qq�q����		U		^   ����   - < D  32+"&=467";26=4&##"&4632'"2654'.'&6264&"��#22#�#22#5KK5�5KK5+'%%=,??X?'W�2#�#22#�#2+K5�5KK5�5K�+%5&T?X??,'  U  ��   %  264&+"3264&+"36&#"327�		�
V�		��U
��c��    ����   1  '7".54632&32654'72?64/7654.#"1&#"�,",�,9''9�P:9Q;1J1
	�$-9+-"-��,(8O8�rQQ:.$;1K1
�    ����   ! 2  7&?64/&&?654&'&?654&Z�����

��

��

���
�

��+RRS`kkjj�jje�kkf  *����   + 7  !2#!"&5467"3!2654&#"3!264&#2654&"k*		��		%%*%%��		�		��k	��		*	*%��%%*%���		 		    	 ����     + 7 C O [   "&4624&"2"&4624&"2&"264/"264/&2?64&"2?64&"2?64&"�q�qq�����
?X??X&4&&4�[[�[[$[[LL�[[�qq�q���X??X?�4&&4&�[[�[[r[[LL�[[    ���� & K  7"32?654&#"32?632#"'&326'&#"32?64&"#".54?632�'@5%A#K53&$
$"$2@#+K'@5%A#K53&$$"'@#+�4&@&35K$$
%2$"@"	4&@&35K$$%("@"	     @ @  6 D  72>54&+"&46;2654.+"3";2+";264&#";264&#�
	@#22#@	@5KK5�	
@#22#@		@5KK5�	
�		@
	2F2	KjK 
2F2KjKk	
  ����  . 2 D L T  2#54&"#54>";26=462;26=453';2654&+"6"&4624&"2U,?+%5&*1[{X
V	U	�U+V
U		U
�%5&&5 ?,��%%��1+X>�
	�		�		�>����� 
	 	
5%%5&I     * *�V   + 9 G R  "3!264&#"3!264&#"3!264&#%32>54&#"3264&#"264&#"�
		��	
		��	
		�k	
	

			
U
�	
�	
�	
	
�
�	
    ����   % 1 ? K W c  4.#"2654&"265"264/"264/%";264&#!";264&#2?64&"62?64&"
	�<=�=<��	
U		 		U		�=<�<=�
	U		� 		U		�=<�<=`	
�<=�=<   *����   9  7!2#!"&=467"3!26=4&#32>=46226=4&"k*		��		%%*%%��
2F2KjK�	�		�	+&�&&�&
V#22#VV5KK5    *����  , 8  ";2+";2654&#2?64/&"7264&#!"3@		U		U		U%%�kk\\z		� 		�	��	%*%��kk\\G  *����  , 8  264&+"&546;264&+"362?64/&"7264&#!"3�		U		U		U%%�kk\\z		� 			*	%��%skk\\G       ��    0  !2#!"&5467"3!2654.#4&'&?6UV��&&V&@��	��	U	� 		 	+&� && @����   �� �  ! 1 =  ?'&7??654&'&74.#"32654&"265L

u��

u����

���

��
	
�-D��IPD%I=��PPUUPPUI
	��
	 		��		  *����   & 1  %&'&54624&"7676'#"&4624.#"26�K*66*Kd�d*}�}U6>>6U�'#22F2*$�MV1((1VMFddFX}}X]b?))?b]'2G22##     *����  / C W  74&#";2654.+"&5";26=4&#"#26=4&+";2%264&+"26=463U	
%@
@	 
@%	
	%@@	� @%	k
@%	
		
%@
@	 @%	%@@	   *����  ! - 9  ";26=4&#264&+54&"3 4&"2? 2?64&"@		k	� 		k	����\���k		�	�Vk		�	�������  ����    , :   "&4624&"2'";264&#'3264&#"3264&#"�q�qq����¶	
�		�		
�		
�qq�q����	
k

   * *�V   #  7"3!264&#%"3!264&#"3!264&#@		�		��		�		��		�		���    '����  5  7&76'&54>76;#"*#"?32676=.�Q'"(=UM/%0	/)!3&q',:b5V<Q"(7+V=
%"(0j6G','q&=3*12V4   *����  !  %#!"463!254&#!"2?326�	� 1	*	*%��%O�%�	1"		��%%��	O&   U����   3 ? K  2"&=4>"26=44&""&=4&#"2654&"265'";264&# $/F22F2VKjK
d�d�j		�		���+2#�#22#�#�+5KK5+
+GddG�V      �� �  % ; K c o {  "264'26=4&#"327>32"2764&"#".=44&"326764&"#"&=4&#"3274&"265'";264&#��*2#/b2F�
+%65K
dGH2ej		�		��*��		r#2'V	@#2@	+
c&K6+
,Gd3V     *����  - C Y  7";2326=4&#326=46;264&+"72654.+"&=4&"3'4&#"+";265@
	@	
&�
		@		@&�	
@	&�
	@		@&�
		@
	@&�
	@	&�
	@		@&�	
@	&     *����  ! - 9  7";26=4&#7264&+54&"3&2?64&"2?64&"Uk	�k	������k�	*k�	�����  U �� �   7"3!264&#k		*		�  ����      "&4624&"2";264&#�q�qq����¶	
�		�qq�q��� 	
  *����   -  !2#!"&5467"3!2654&#";264&#k*		��		%%*%%�	
�		k	��		*	*%��%%*%�	
    ����    . <  !2#!"&=467"3!26=4&#";264&#'4.#"265UV		��		&&V&&� 	
�		@
	k
�		�	*%�%%�%��	
@
	U		   +����  *  %27#"&54677"&547654&#32674&g/A$Gd?2
-O.5K
5Y4~XSz!}
!3dG7W.O-*K5+!	:^7X}pS    @ �� �     $4&"264&"2%4&"32>+$$�##��#�$$$$+$  �  +�     $4&"264&"24.#"26+$$$$$�$$�##� #  &  ��      72?654'%&6"264/�mh
!2����U��Rm#
��2������     ����  + < P ^ l  4.#"264/76732?32>54/&"4&"'&"2?6"32?64/"3!264&#'4.#"265�
	@@11+	11	
@@�11@@O11
	@@��	
�		�
	 
@@11^11
	@@��11@@�11	
@@:	
�
	�V		     *����   ' 2 A  7265732654&"&4624&"2%#"&4624&"32>��
	� 
*2F22F$$C'#22F2*$
@		$�
	
+�F22F2g$$V'2G22##
    *����    %'&/%276&q)���k�(?q�����(�  Q����    %&?6'&"bmm�����h8(��)PP�     ����    73#/;2?6=4/&+"@q�qq�q+}	�	}}	�	}qq�qq��	}}	�	}}   *����   0 @ L  %>54/&/&=4?6&?6=4'&?654&26=4&"U

�
v����  �  �  �  ��
��
��o��U�UU�JU%�%UU%�%llf�		�		     ���� 3  $4&""&4?62"&4?64&"32?64&"2?��X?�5&���
�>X �,X|+���?X�%6���
�X? �+|X,�  j  ��    (  73;2654&+"3;2654&+"�+U	U		U	�+V
U		U	+*��@��V��*��@��
V    ����    ,   "&4624&"2'26=4.#"26=4&#"�q�qq����¡U
�qq�q����		�	�		�	
     ����    ( 4 < H  %'72?64/&"''&?6/&'$"264/"&4624&"32@"w"��@�][�?K�k �����&5%%5	"w"��@��[2�� k�L���5&&5%H	   @  ��    # +   4&"27&"&4624&"2"&4624&"2���*�+>,,>(,>++>M��*>++>,X�>,,>+X     ���� # F  %'&'&'&'4>;2?6'&&'7>'&'.+">=4&'&�	_PJ/4
@	1W	 !D$;'	
$@':4RYi  	
4/JQ_! 	W1	N	';$ *jYR49A$   ��� # F Z j  %'&'&'&'4>;2?6'&&'7>'&'.+">=4&'&&326'.&326'.�	_PJ/4
@	1W	 !D$;'	
$@':4RYi  T	;U	kH
%
8	
4/JQ_! 	W1	N	';$ *jYR49A$%		U;
JjN
%
&8    �� �   C f   "32?64/";264&#'&'&'&'4>;2?6'&&'7>'&'.+">=4&'&�GG
	VVd		�+	_PJ/4
@	1W	 !D$;'	
$@':4RYi  �GF	
UVO��	
4/JQ_! 	W1	N	';$ *jYR49A$   �� �   @ c  4&";264&+64&"2?'&'&'&'4>;2?6'&&'7>'&'.+">=4&'&k�		j���:	_PJ/4
@	1W	 !D$;'	
$@':4RYi  �		�w���{	
4/JQ_! 	W1	N	';$ *jYR49A$     �� �   > a   4&"2?&"32>54/'&'&'&'4>;2?6'&&'7>'&'.+">=4&'& ����	
�F	_PJ/4
@	1W	 !D$;'	
$@':4RYi  ���$�
��]	
4/JQ_! 	W1	N	';$ *jYR49A$   �� � ' Y e  726'&'4>;22?>'&'.+"6"?6#&'&'&"6=.'&'&&'$4&"27o4
@		
$@':�"+	 !

_P%"(Yi*$�*֙Pa

! 	$ )iY
 #		?	4"9'A
	��*�  �� �   B e  32>=4&+";2?64&"'&'&'&'4>;2?6'&&'7>'&'.+">=4&'&�	
�		j���z	_PJ/4
@	1W	 !D$;'	
$@':4RYi  +	
�w����	
4/JQ_! 	W1	N	';$ *jYR49A$    ����    .  %6&#"#"&5467654&326'54&#";26�`;Pp@3?N�aHu�C_,�a		�	e4ApP:`uGa�P��_Ca�	�	    U����    7%7%64'%&����+

��'2������    ����       "&4624&"2'5'?64/&�q�qq�����vDo�		��qq�q����Z-U�UU  U �k    4.#"32>5'"3!264&#
		�		*		U
	��	�  ����    +   "&4624&"24.#"265'";264&#�q�qq�����L
	j	
�		�qq�q���@
	�		j	
  *����   - ;  !2#!"&5467"3!2654&#4.#"265'";264&#k*		��		%%*%%�
	j	
�		k	��		*	*%��%%*%�
	�		j	
    ����   5  !2"&=4>7"326=4&#"2?654.#"'&UVq�q
&?l@a�&� 
UU
FFk	�OqqO�
*%�?l?�a�%�
	VV	
GG  *����  %   ""&4764&"264/4.#"265�2d�d2>}�}>�
	H2�dd�2>�}}�>T
	�		    ����  D H Z  26=326=4.#!"264&+".=463!2+";26=4&#!"353%3!26=4.#!"k�
� 			+V+		+&&��&&@�� 	 	
� 
 		��		�
	��j		j	%j%%j%+����		�

   �m    2 B R  $"&4624&"2264'&4764&""2764'& 264'&4764&"6"2764'@&4&&4�88EE=
88EE��  ++�  ++�4&&4&I�9�9E�ER		9�9E�E��X ,|,�X ,|,      �� �  ! : S  4&";264&+26=4&+";74&#"#"&'&#"32?6'26'.#"32?632+�		j��		j+	c2G7X;R.X?bKnEX>c	c2G%A/k�	��		^2@2)A$>]_?O>]		]33!      �� �  % @ X  4&"#";26526=32>54&+"7"32676.#"#"/&7327>3232654/&#" j
	�� j
	�b?XEn	/A%G2cX7G2c	c>XEnkk
		�k
				]>O?!32^\2@3]		]>O     *����  & : P   "2?64/26=463!2654.#!"264/7654.#"%4&#"#!"3!265sFFUU��&+	
��,>bFF
UUO	
&��		+,>�GFUV�+&
?,��GF	
UV�
+&?,   �o      '4&6'4&6Ո�
��
�����*�j�*���j�*��     ����  3  4&";264&+"3264&"32?632#"&'&+�		j nEX}}�>c	c2G.O.dG7Xk�	k?P}�}>]		]2-O.Gd@2   *�� �  9  4&"#";2656&#"#"&54>3232654/&#"326 j
	�7/A%Gd.O.G2c	b>Y:b9}XEnkk
		r!3dG.O.3]		]>9b:X}O     @  ��  # .  7"322654&'"3232654.4&"32>UGd}YW�U
a�##�dGY}�U�W
c�a��#  *����   7 L  %!"&546;26=4/&+"3726=4&+"326=3'54.#";2>4.#���		�^	%k	�%%��
	��
�

	*	^�	*%�	k%��%		��
	��U
	k

   *����     + ; G  "&4624&"2"&4624&"2 4&"2?"264/."264/�2F22F$$C2F22F$$.���vv�SScF22F2g$$�F22F2g$$M���	vv�SS   *���� 
    %"&46324&"2264/&"�X{XX>(E(+q�pp�}]]�=XX{X(Ex�pp�q*]]    ����       4&"2?%'&/3276&�����Nu@��Q��V���u���BQ���  ����   / ? N V  !2#!"&=467"3!26=4&#!2#!"&=467"3!26=4&#%32>54&#"264&"UV		��		&&V&&��V		��		&&V&&��	
	�	V		V	+&V&&V&��	V		V	+&V&&V&�	
	�     �� �   w �  $"&4624&"262;2#""'.#".54&'&#""&47>'.+"&4632>7654'&462727>=4623274632654&#"'4&54764&"#"54&""#"'&#"#";227626=4632264'&U2F22F$$k	
!
		
	
!
9&&%5&4&&&	%5
	%5&5%�F22F2g$$�!

	!			
�%&5%&&
	%5&5%&&	%5   @����  7 E  74&#"3!26=4.#"#!"&52>54/&"32?'4.#"265k	
& &
		� 	�
UUFF8
	�	
�&&�
	�
	UU	FFj
	��		    *���� 
   " - 5 B Q   "&54>324&"2"&4624&"2#"&4624&"2'&654'74.?6�2F2'#$$�2F22F$$C'#22F2*$$�
�

	�
�yG22#'g##HF22F2g$$j'2G225##�U�
UU    @����    %&'&=754/&7676�@&//&@��+��N17

71N�@>$$>@�88��@@�RK00K      �� �  9 E  %3276=4/&?%4&767654.#"&'&=6&"264'��D<���
N17

H6
-;0%@\��*��@8���RK0&<2!$>@�t�*�    *����   + C  %#!"&573%3!2654/&#!"7"3!264&#4.#""&54&#"26�	��	6�6��%*%@� @		�		U
&4&
?X?$GG��&&+U		Uj
%%
,??    ���   (  4&"2$4&"2";;26?6&#!'&#�$$##�YD(�##	��$$$$��G�
Z    @����   1 = I  ";26=4&# 2764&"%4&#"#";265&"264/&"264/UV	��k���	
V
k	����kk�Vk	��k��G
V	
	����kk     *����   +  !2#!"&5467"3!2654&#4&"265k*		��		%%*%%�k	��		*	*%��%%*%		��		    U����     '4&6%2654&"���
��
��>�~�V	��	#		*		     U����     75'?64/&4&#"265���
��
V	
B�~���	��	#	
��		  ����    ' / = C O W c i u } � � �   "=4226=4&"7542#264&"32"=7"26=4."435"26=4&#4;2+&;264&+"2"=7#"264.+"4;4&+";2>'"42326=4&"@  ,�  ,�  ,�  ,�jj6 j  j
 	 ,�jj6 j  j6
 	 , jj6 j  j+
+ , 	Ujj6 j  j+
+ , 	+!,  ,�
+   ,�  ,�
+   ,    ����      "&4624&"2"264'�q�qq������.���qq�q������.   	  �� �   % 1 = N \ m y  3326=4&"526=4&"26=4&"526=4&"26=4&"532>=4."";2>54&#7";2>4.#";264&#@
	��	

���
	+	�

+		�
	�		V		�		��		�		Uk		��		kU	�

�
	�	

�    U����   '  32+"&5467";2654&#264&"��		�		&&�&&��	��		V	+&��&&V&��    ����   + : H   "&4624&"2'"2>76&#""&/&'3264&#"3264&#"�q�qq����¶#$#
#&"
		
�		
�qq�q����

			U

    @����   ' / =  !2#!"&5467"3!2654&#"&4624&"2'32>54&#"� 		� 		%% %%?X??X&4&&4/	
	
�	��		V	+&��&&V&�X>>X?�5%%5&�	
	
   *����    !2#!"&5467"3!2654&#k*		��		%%*%%k	��		*	*%��%%*%    ����  1  %'&76/76?&"?6/7654.#/\]

]Li//i�=�bzzb�=�h11hI
__
�|	`�@@�`		|  ����    #   "&4624&"2'53';26=4&+"�q�qq����V�	�		�	�qq�q����VVk�		�	    
  �� �    ) 5 A O ] i u  $"&4624&"24&#"2654&"265"264/ "264/%";264&#!";2>4.#2?64&" 2?64&"U2F22F]KjKKj 	
�(�~
+		�		+

�Z'�F22F2�jKKjKk
+		��		+����
	

�(      �� �  ! - 9 G S _ w  %2654&"2654624.#"265"264/";264&#!";2654.#&2?64&"264&#!"332?32>54/&"UKjK2F2@
	�T+		�		+
yr�*�FF
UU@		5KK5		#222
	�		�	
|��kFF
	UU    �� �  ! - 9 G S _ p  %2654&"265462'26=4.#""264/";264&#!";2654.#&2?64&"264&#!"3 4&"'&"2?UKjK2F2j
	�T+		�		+
yr�*VFFUU@		5KK5		#22�		�
	��	
|��xFFUU    @����   '  !2#!"&5467"3!2654&#264&"� 		� 		%% %%��	��		V	+&��&&V&��     ���� 
  (  %"/534/&+"2?$32>54&"���1�	�	�5���	���4�	�	���  ����     ' /   "&4624&"2"&4624&"26"&4624&"2�q�qq�����
?X??XiW|WW|&4&&4�qq�q���X??X?�|WW|W�4&&4&I  @ �k    62?64/&#"";264&#@��	
qq�		�^��
qq$    ��k�  #  %"&5476=4622654'54&"
!,>+!	�EaE+,>++�(++(���0EE08#�,,�#    ����  2 =  74.+.?>;.5%54'.#!";32?3>76'32+�
z	
�M (��&b2#P,'k%
$�

�ѭ��#�%@$2�#�
�    ����  + 5  ;+5734.#"#"3!26?6&+#"&=46;	z	�M+'P2&&1$'d�++ 	
�
ѭ
'�&�&�+���      k    '  32+"&467";264&#"&4624&"2��6JJ6�6JJ6GddG�GddGU2G22G##@KjKKjK+d�dd�d�F22F2g$$      k    '  32+"&467";264&#"&4624&"2��6JJ6�6JJ6GddG�GddGV2G22G##@KjKKjK+d�dd�d�F22F2g$$   *���� ! " A B   2?#"'&"&4?6'&5463276&'&#"2?32654'.1'1##6>-
��
	>,6@.
!>W�'7�>XP"<#"7,>	
��
,?6@rX>�7'�X=!
P"     *����  , J  "3!264&#326=46;232>=4&+"4&"+"&54.";265@		�		��V
&V&�

&�&U	++	
+&&+		��+

��&&    *����  , J V d  "3!264&#326=46;232>=4&+"4&"+"&54.";265'4&"26574&"3265@		�		��V
&V&�

&�&�U
U	++	
+&&+		��+

��&&�		��		�
     *����   # 4 8 J  !2#!"&5467"3!2654&#53';26=4&+"53';2>=4&+"k*		��		%%*%%�@
@		@�@	@@	k	��		*	*%��%%*%�땕��
	�^@@Vk	k     * V  (  "2?264/&"'";26=4.#"�\��\�G		�
	U�\��\��	�
	k     * V  .  4.#"'&"2?2?6'";32>=4.# 
	�\��\��
	j	

@
�\��\�
	k	
�
   ����    2#!"&73!2654'&"������%j%	�H�m
��
.��%%.��      �� �    * 2 : B J  75!3!2654&#!"53';26=4/&+""&4624&"2$"&4624&"2+��@		��k73��@V�,>++>R+>,,>��� ��			���3M��		j	@�>++>,X?>++>,X   ����   0  !2#!"&=467"3!26=4&#&4&"'&"2?UV		��		&&V&&+\\kk	�		�	+%�&&�%M[[jj    *����   ( B  %32>=4.#"26=4&"'!#"54&+!";2?32?654.@

k�VIb	1Vk��		UObU
�
V
VVU�I1"	V	��	@	OU 
      ��� & M  2767#"'67654'.547726=46'">545676&&#".'&#"`!	�i##)$	.>$i=	,�09=�t^6!,1D/P9k
z�	JF#.4	,��"%M}K#.		E1.&.:{     @  ��  ' 3  26=!32>=4&#!"";2>4.#4&"265@*	
���	
�

++		**	
@��	


@��      �� � $ -  %4&"#"&=3265.#";3267!>32��	GqBd�	� #2=�\vM&G7&+�
@k=�d	�2�Ja.?    @����  '  4&"26=4&""&5"3!2654.#�W|W?X?@
V
�		�>XX>�		�,??,�	
	
    *����   9  7!2#!"&=467"3!26=4&#32>=4632326'.#"k*		��		%%*%%��
2#/		F.5K�	�		�	+&�&&�&
V#2'
,;K5     *����  , 8  %4&"#!"&=4&"3!265&264/&"2?'4&"265�	��	%*%skk\\G�		U		U		U%%�kk\\z		� 		   �����   I Z  $264/&"2?'4&"26577>54.+.#"32654'&54632;2&264/&"2?MUUFF1�$";#c>Pp0
	%W>3Q#2QUUFFUUUFFd		�		8<##;"9GpPH7	+8>X@02$)UUFF   @����  ' 2  !26=4.+"326=46;2"&4624&"32�1�,?
	%�*?X??X&4&		+1>,+
	+&,X??X>�5&&     �� �  + 6 J  !326=4&+"326=46;2#".54624&"3:"2?654.#"'@
	?,�,?
	%�& ?,(>X%5&�*V
	G
	+,>>,+
	+&&,X?(,>�5&&*U	
F   �� �  + 6 B  !326=4&+"326=46;2#".54624&"32264&+"3@
	?,�,?
	%�& ?,(>X%5&�		
	+,>>,+
	+&&,X?(,>�5&&+     �� �  + 6 E Q  !326=4&+"326=46;2#".54624&"3274&"32657264&+"3@
	?,�,?
	%�& ?,(>X%5&�+�		
	+,>>,+
	+&&,X?(,>�5&&*		�+    �� �  + 6 B Q  !326=4&+"326=46;2#".54624&"326"264/4&"32?@
	?,�,?
	%�& ?,(>X%5&�kkqk
	k
	+,>>,+
	+&&,X?(,>�5&&@jjj
j      �� �  ' 2 D Z  !26=4.+"326=46;2"&4624&"32 26=4&'&&7>54.U1�,?
	%�*?X??X&4&-#z#-$		+1>,+
	+&,X??X>�5&&� 	+%9	
"+	�
"-"
	:%+        k    .  %7'6=4&%32+"&=467";26=4&#z[�		��b�		�		%%�&&�A�Rk�	�		�	+&�&&�&   �� �   F R  ";2?26=4&'54&#4.#"+"&=46;264&+";265 "264'�H^q&@
�		++&&�&����*kHD��R?&� 
�&�&&k�*�    U @ 
   & 2  7"&46324.#"2>"&4624&"2%"3!264&#�,>++"+,0EEaE�+>,,>VEaEEa���,,>+"+EaEEP>,,>+{aEEaE+     o 	   732?'&+654&#";@@HH@�dN

N�:�:{*Q
�
   �o 	  0  732?'&+654&#";6"32764'@@HH@�dN

N�
%%�:�:{*Q
�
�F	
%j&    �o 	  0 C  732?'&+654&#";6"32764'7"2764'&@@HH@�dN

N�
%%<
88EE�:�:{*Q
�
�F	
%j&Q		9�9E�E     o 	  , ;  732?'&+654&#";%4.#"2?6'"264/&@@HH@�dN

N�
	���
���:�:{*Q
�
�
��
	��     U����    @ a  $"&4624&"2'4&"264/26?>;2326/.+""+"&/.#"3:32>?6&�KjKKjvd�dd�2 u	]		$\�	[	
%?�jKKjKǎdd�d�		@	 TRR	
R"R	�R	
R
R"R
     �k  ' 9 A  77632654'&#"'32763232654'&#"7632654'&#"264&"U
<MM;G]]GL	[yy[	g��g�
""	'00&	J�	11	
;;B	PP	
[[�U      �� �   + @ P b j  "264'&32>54'&767>54&7363232654'&#"32767654&7632654'&#"264&"��*J
��
,9

C5z
y[	g��	*4;0�
""	'00&	J��*��	
%	%	
,�	P	
[]	%*�U     ��  2 M   2762#!"3!264&""2654.#!"3!2"262+";264&"e
��	
u,,>V5&� 		 	k�		�&&5$		
+>,�&<	&4&   j *�V     4&"27$"264'��  �� � 7�  $�     ����    '   "&4624&"24&"2?&"264/�q�qq�����������qq�q���"��$��  ����   + 7  73#/;2?6=4/&+"4&"2?&"264/@q�qq�q+}	�	}}	�	}@�����qq�qq��	}}	�	}}*��$��  *����   + 7  !2#!"&5467"3!2654&#"264/4&"2?k*		��		%%*%%������k	��		*	*%��%%*%�����   �� �  7 : F  "'&'&'&7676?6'.'&'&"2767>'54/&?�D[-.DD[�/I/\/I
I/\/I�;{zz2lmlm�tv !8A8; �!B-F�F     '����  !  7;76&+7;3276&+76&#"�	��	���	�

�	�

�
�c
��
�
 
�
    �� �  ( E Q  32>?6&#"32732?6&+";32?6&#"76&+76&#";"264'�	

4	�	
3	r	
D�

k
<	�M	
j	����*3	f
>�
>

�
�
Hc
]
�
@�*�    *���� 
   , 8  %"&46324&"2264/&"4.#"265'";264&#�X{XX>(E(+q�pp�}]]�
	U		��=XX{X(Ex�pp�q*]]$
	�V    *���� 
   *  %"&46324&"2264/&"%";264&#�X{XX>(E(+q�pp�}]]��		��=XX{X(Ex�pp�q*]]�      �                          +       # {        �        �        �  	        	     	     	  F 3  	   �  	    �  	   �    F e a t h e r  Feather  R e g u l a r  Regular  F o n t F o r g e   2 . 0   :   F e a t h e r   :   3 0 - 7 - 2 0 2 0  FontForge 2.0 : Feather : 30-7-2020  F e a t h e r  Feather  V e r s i o n   0 0 1 . 0 0 0    Version 001.000   F e a t h e r  Feather        ��                     "     	
 !"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWX �YZ[\]^_`abcdefghijklmnopqrstuvwxyz{|}~������������������������������������ ������������������ ���������� ������������������������������ ����������������������������������� 	
 [activityairplayalert-circlealert-octagonalert-trianglealign-centeralign-justify
align-leftalign-rightanchoraperturearchive
arrow-downarrow-down-circlearrow-down-leftarrow-down-right
arrow-leftarrow-left-circlearrow-rightarrow-right-circlearrow-uparrow-up-circlearrow-up-leftarrow-up-rightat-signaward	bar-chartbar-chart-2batterybattery-chargingbellbell-off	bluetoothboldbook	book-openbookmarkbox	briefcasecalendarcamera
camera-offcastcheckcheck-circlecheck-squarechevron-downchevron-leftchevron-right
chevron-upchevrons-downchevrons-leftchevrons-rightchevrons-upchromecircle	clipboardclockcloudcloud-drizzlecloud-lightning	cloud-off
cloud-rain
cloud-snowcodecodepencodesandboxcoffeecolumnscommandcompasscopycorner-down-leftcorner-down-rightcorner-left-downcorner-left-upcorner-right-downcorner-right-upcorner-up-leftcorner-up-rightcpucredit-cardcrop	crosshairdatabasedeletediscdivide-circledivide-squaredollar-signdownloaddownload-clouddribbbledropleteditedit-2edit-3external-linkeyeeye-offfacebookfast-forwardfeatherfigmafile
file-minus	file-plus	file-textfilmfilterflagfolderfolder-minusfolder-plusframerfrowngift
git-branch
git-commit	git-mergegit-pull-requestgithubgitlabglobegrid
hard-drivehash
headphoneshearthelp-circlehexagonhomeimageinboxinfo	instagramitalickeylayerslayout	life-buoylinklink-2linkedinlistloaderlocklog-inlog-outmailmapmap-pinmaximize
maximize-2mehmenumessage-circlemessage-squaremicmic-offminimize
minimize-2minus-circleminus-squaremonitormoonmore-horizontalmore-verticalmouse-pointermovemusic
navigationnavigation-2octagonpackage	paperclippausepause-circlepen-toolphone
phone-callphone-forwardedphone-incomingphone-missed	phone-offphone-outgoing	pie-chartplayplay-circleplus-circleplus-squarepocketpowerprinterradiorefresh-ccw
refresh-cwrepeatrewind
rotate-ccw	rotate-cwrsssavescissorssearchsendserversettingsshareshare-2shield
shield-offshopping-bagshopping-cartshufflesidebar	skip-backskip-forwardslacksliders
smartphonesmilespeakersquarestarstop-circlesunsunrisesunsettablettagtargetterminalthermometerthumbs-down	thumbs-uptoggle-lefttoggle-righttooltrashtrash-2trellotrending-downtrending-uptriangletrucktvtwitchtwittertypeumbrella	underlineunlockuploadupload-clouduser
user-check
user-minus	user-plususer-xusersvideo	video-off	voicemailvolumevolume-1volume-2volume-xwatchwifiwifi-offwindx-circle	x-octagonx-squareyoutubezapzap-offzoom-inzoom-out     ��        ڶ��    �G�{    �G�{